<template>
  <div class="wrongquestion animate__animated animate__fadeIn">
    <div class="header">
      <Headers :header_data="[{title:'AI单词本',path:'wordbook/question'},{title:'错题库'}]"/>
    </div>
    <main>
      <div class="tabs">
        <el-tabs tab-position="left" v-model="tabs" @tab-click="tabClick" :stretch="false" style="height: 200px;">
          <el-tab-pane name="1">
            <div slot="label" class="tablabel">
              <i>
                <img src="../../../assets/images/student/bluecuo.png" v-if="tabs == 1" alt="">
                <img src="../../../assets/images/student/ccccuo.png" v-else alt="">
              </i>
              <span>错词本</span>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="我的收藏" name="2">
            <span slot="label" class="tablabel">
              <i>
                <img src="../../../assets/images/student/bluexing.png" v-if="tabs == 2" alt="">
                <img src="../../../assets/images/student/cccxing.png" v-else alt="">
              </i>
              <span>我的收藏</span>
            </span>
          </el-tab-pane> -->
        </el-tabs>
      </div>
      <div class="main_right">
        <div class="main">
          <p><span>错题表</span></p>
          <el-table style="height:75%;" :data="tableData" border :header-cell-style="{background:'#F1F8FF',color:'#333','text-align':'center'}">
            <el-table-column prop="index" label="序号" />
            <el-table-column prop="dcmc" label="单词" />
            <el-table-column prop="cccs" label="出错次数" />
            <el-table-column prop="ccly" label="错词来源" />
            <el-table-column prop="cctx" label="出错题型" />
          </el-table>
          <div class="pagination">
            <el-pagination background layout="total, prev, pager, next" @current-change="handleCurrentChange" :current-page="pages.currentPage" :page-size="pages.pageSize" :total="pages.total" />
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>
<script>
import { getErrorWordList } from '@/api/student'
export default {
  components:{
    Headers:(resolve) => {
      require(['@/components/student/header.vue'],resolve)
    },
    Footer:(resolve) => {
      require(['@/components/student/footer.vue'], resolve)
    }
  },
  data(){
    return{
      tabs:'1',
      tableData:[], //表格数据源
      pages:{
        currentPage:1,
        pageSize:10,
        total: 0
      }, //分页器
    }
  },
  created() {
    this.getErrorWordList()
  },
  methods:{
    // 获取列表
    getErrorWordList() {
      getErrorWordList({ bjid: Number(this.$route.query.bjid), currentPage: this.pages.currentPage, lyid: Number(this.$route.query.jxid), pageSize: this.pages.pageSize, xsid: this.$store.state.userInfo.yhid }).then(res => {
        if (res.success) {
          res.result.list.forEach((item,index) => {
            item.index = (this.pages.currentPage - 1) * this.pages.pageSize + index + 1
          })
          this.tableData = res.result.list
          this.pages.total = res.result.total
        } else {
          this.$message.error(res.resultDesc)
        }
      })
    },
    // tab切换
    tabClick(){},
    // 分页器页码
    handleCurrentChange(val){
      this.pages.currentPage = val
      this.getErrorWordList()
    }
  }
}
</script>
<style lang="scss" scoped>
.header{
  width: 100%;
  height: 90px;
  box-shadow: 0 2px 4px 2px rgba(89,144,255,.1);
}
main{
  position: absolute;
  top: 90px;
  left: 0;
  bottom: 50px;
  right: 0;
  box-sizing: border-box;
  padding: 2% 0;
  .tabs{
    height: 100%;
    width: 10vw;
    border-radius: 10px;
    box-shadow: 0 0 8px 4px rgba(89,144,255,.2);
    float: left;
    box-sizing: border-box;
    padding-top: 2%;
    .tablabel{
      position: relative;
      display: block;
      width:fit-content;
      width:-webkit-fit-content;
      width:-moz-fit-content;
      height: 100%;
        // margin: auto;
      i{
        width: 1.1vw;
        height: 1.1vw;
        display: block;
        float: left;
        margin-top: 3px;
        margin-right: .4vw;
        img{
          display: inline-block;
          width: 1.1vw;
          height: 1.1vw;
        }
      }
      span{
        display: block;
        float: left;
      }
    }
  }
  .main_right{
    padding: 0 2%;
    float: left;
    width: calc(100% - 10vw);
    height: 100%;
    .main{
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      box-shadow: 0px 0px 16px 0px rgba(89, 144, 255, 0.3);
      border-radius: 10px;
      padding: 0 2%;
      position: relative;
      p{
        width: 100%;
        height: 12%;
        text-align: center;
        position: relative;
        color: #333;
        font-size: 1.1vw;
        span{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .pagination{
        position: absolute;
        bottom: 3%;
        right: 1.2%;
      }
    }
  }
}
</style>
<style lang="scss">
.wrongquestion{
  .el-tabs__header.is-left {
    width: 10vw;
    .el-tabs__active-bar{
      display: none;
    }
    #tab-first {
      font-size: 20px;
      padding: 0;
      text-align: center;
    }
    .is-active {
      background-color: #d8effb;
    }
    .el-tabs__header.is-left .el-tabs__nav > div {
      width: 3px !important;
    }
  }
  .el-tabs__nav{
    padding-top: 10px;
  }
  .el-tabs__item{
    color: #999;
    height: 3vw;
    line-height: 3vw;
    font-size: 1.15vw;
  }
  .el-tabs__item.is-active {
    color: #409EFF !important;
    border-right: 5px solid #409EFF;
  }
  .el-tabs__item:hover {
    color: #409EFF !important;
  }
  .el-tabs__header.is-left .is-active{
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px 0px rgba(89, 144, 255, 0.3);
  }
  .el-table--enable-row-transition .el-table__body td{
    text-align: center;
  }
  .el-table tr:nth-child(even){
    background: #fafcfe !important;
  }
}
</style>